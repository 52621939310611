const fab = document.querySelector(".floating-action-btn");
const fabBtn = document.querySelector(".floating-action-btn__button");
const whastappBtn = document.querySelector(".floating-action-btn__whatsapp");
const instagramBtn = document.querySelector(".floating-action-btn__instagram");
const facebookBtn = document.querySelector(".floating-action-btn__facebook");
const footer = document.querySelector("#footer");

const TABLET = 1280;
const MOBILE = 800;

let isMobile = false;
let clicked = false;

const startWhatsapp = () => {
  if (isMobile) {
    if (window.innerWidth <= MOBILE) {
      return "164px";
    }

    return "186px";
  }
  if (window.innerWidth <= TABLET) {
    return "326px";
  }

  if (window.innerWidth <= MOBILE) {
    return "316px";
  }

  return "164px";
};

const startInstagram = () => {
  if (isMobile) {
    if (window.innerWidth <= MOBILE) {
      return "112px";
    }

    return "126px";
  }
  if (window.innerWidth <= TABLET) {
    return "276px";
  }

  if (window.innerWidth <= MOBILE) {
    return "266px";
  }

  return "106px";
};

const startFacebook = () => {
  if (isMobile) {
    if (window.innerWidth <= MOBILE) {
      return "112px";
    }

    return "126px";
  }
  if (window.innerWidth <= TABLET) {
    return "226px";
  }

  if (window.innerWidth <= MOBILE) {
    return "216px";
  }

  return "106px";
};

const endBtn = () => {
  if (isMobile) {
    return "58px";
  }
  if (window.innerWidth <= TABLET) {
    return "160px";
  }

  if (window.innerWidth <= MOBILE) {
    return "160px";
  }

  return "32px";
};

const startEvent = () => {
  if (!clicked) {
    fabBtn.classList.remove("rotate-out");
    fabBtn.classList.add("rotate-in");
    whastappBtn.style.bottom = startWhatsapp();
    instagramBtn.style.bottom = startInstagram();
    facebookBtn.style.bottom = startFacebook();
    footer.style.bottom = "0px";
    clicked = true;
  } else {
    endEvent();
  }
};

const endEvent = () => {
  fabBtn.classList.remove("rotate-in");
  fabBtn.classList.add("rotate-out");
  whastappBtn.style.bottom = endBtn();
  instagramBtn.style.bottom = endBtn();
  facebookBtn.style.bottom = endBtn();
  footer.style.bottom = window.innerWidth <= MOBILE ? "-72px" : "-82px";
  clicked = false;
};

window.addEventListener("load", () => {
  const platform =
    navigator?.userAgentData?.platform ||
    navigator?.userAgent ||
    navigator?.platform ||
    "unknown";

  if (platform.includes("Android") || /iPhone|iPod|iPad/.test(platform)) {
    isMobile = true;

    fabBtn.style.bottom = "46px";
    fabBtn.style.right = "48px";

    fab.style.bottom = "38px";
    fab.style.right = "38px";

    whastappBtn.style.right = window.innerWidth <= MOBILE ? "52px" : "60px";
    whastappBtn.style.bottom = "52px";

    instagramBtn.style.right = window.innerWidth <= MOBILE ? "52px" : "60px";
    instagramBtn.style.bottom = "52px";

    facebookBtn.style.right = window.innerWidth <= MOBILE ? "52px" : "60px";
    facebookBtn.style.bottom = "52px";
  }

  fab.addEventListener("click", startEvent);
});
